// @flow

import React from 'react';
import type { Node } from 'react';

function Editor(props: { lines: Array<Node>}) {
  return (
    <table className="Editor">
      <tbody>
        {props.lines.map((line, index) => 
          <tr className="Editor__line" key={index.toString()}>
            <td className="Editor__lineNumber">{index + 1}</td>
            <td className="Editor__lineContent">{line}</td>
          </tr>
        )}
      </tbody>
    </table>
  );
}

export default Editor;
