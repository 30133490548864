// @flow

import axios from 'axios';
import Editor from 'Editor';
import { Comment, Email, Link, Text, Title } from 'EditorLines';
import React, { useEffect, useState } from 'react';
import type { Node } from 'react';
import 'App.scss';

function App() {
  const [lines, setLines] = useState([]);

  function factory(line: { type: string, content: string }): Node {
    switch (line.type) {
      case 'comment':
        return <Comment content={line.content} />;
      case 'email':
        return <Email address={line.content} />;
      case 'link':
        return <Link href={line.content} />;
      case 'newline':
        return <></>;
      case 'text':
        return <Text content={line.content} />;
      case 'title':
        return <Title content={line.content} />;
      default:
        throw Error(`Unknown type ${line.type}`);
    }
  }

  useEffect(() => {
    async function fetch() {
      const response = await axios.get(`${process.env.REACT_APP_API_HOST || ''}/api/v1/me`);
      const lines = response.data.map(element => factory(element));
      setLines(lines);
    }

    fetch();
  }, []);

  return (
    <div className="App">
      <Editor lines={lines} />
    </div>
  );
}

export default App;
