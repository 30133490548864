// @flow

import React from 'react';

export function Title(props: { content: string}) {
  return <b>{props.content}</b>;
}

export function Text(props: { content: string }) {
  return <>{props.content}</>;
}

export function Comment(props: { content: string }) {
  return <i>&#47;&#47; {props.content}</i>;
}

export function Email(props: { address: string }) {
  return <a href={`mailto:${props.address}`}>{props.address}</a>;;
}

export function Link(props: { href: string }) {
  return <a href={props.href} target="_blank" rel="noopener noreferrer">{props.href}</a>;;
}
